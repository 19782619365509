<template>
  <div>
    <div class="form-container">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container mt-md-5">
            <vue-page-transition name="fade-in-right">
              <router-view></router-view>
            </vue-page-transition>
          </div>
        </div>
      </div>

      <div class="form-image">
        <div class="l-image shadow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style src="@/assets/css/plugins.css"></style>
<style src="@/assets/css/authentication/form-1.css"></style>
<style src="@/assets/css/forms/theme-checkbox-radio.css"></style>
<style src="@/assets/css/forms/switches.css"></style>
